import { Button, Form, message, Modal } from "antd";
import AddPaymentMethodForm from "features/consumerPortalPaymentMethods/components/addPaymentMethodForm";
import {
  useCreateAchPaymentMethodMutation,
  useCreateCardPaymentMethodMutation,
} from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import moment from "moment-timezone";

export default function AddPaymentMethodModal({ open, onCancel, onOk }) {
  const [createAchPaymentMethod, { isLoading: isAchLoading }] = useCreateAchPaymentMethodMutation();
  const [createCreditCardPaymentMethod, { isLoading: isCardLoading }] =
    useCreateCardPaymentMethodMutation();
  const [form] = Form.useForm();

  const handleAddPaymentMethod = async () => {
    // Using Object.values because the form has a nested object where prefix is the key
    const values = Object.values(await form.getFieldsValue())[0];
    // transform name into firstname and lastname
    const [firstName, lastName] = (values.name ?? "").split(" ");
    const { paymentMethod } = values;

    const transformedValues = {
      ...values,
      firstName,
      lastName,
    };

    // validation for bank account
    if (paymentMethod === "process_ach") {
      if (values.bankAccountNumber !== values.confirmBankAccountNumber) {
        form.setFields([
          {
            name: "confirmBankAccountNumber",
            errors: ["Account numbers do not match"],
          },
        ]);
        return;
      }
    }

    // post-transformation for credit card
    if (paymentMethod === "process_card") {
      transformedValues.cardExpMonth = moment(values.cardExpDate, "MM/YY").format("MM");
      transformedValues.cardExpYear = moment(values.cardExpDate, "MM/YY").format("YYYY");
    }

    const result =
      paymentMethod === "process_card"
        ? await createCreditCardPaymentMethod(transformedValues)
        : await createAchPaymentMethod(transformedValues);

    if ("data" in result) {
      message.success("Payment method added successfully");
      onOk();
    }
  };

  const initialValues = {
    newCreditCard: {
      paymentMethod: "process_card",
    },
  };

  return (
    <Modal
      title="New Payment Method"
      onCancel={onCancel}
      open={open}
      width={700}
      footer={[
        <Button
          loading={isAchLoading || isCardLoading}
          key="submit"
          type="primary"
          onClick={handleAddPaymentMethod}
        >
          Add Payment Method
        </Button>,
      ]}
    >
      <Form labelAlign="left" layout="vertical" form={form} initialValues={initialValues}>
        <AddPaymentMethodForm />
      </Form>
    </Modal>
  );
}
