import { createSlice } from "@reduxjs/toolkit";
import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import moment from "moment-timezone";
import { paymentsApi } from "features/consumerPortalPaymentPage/paymentsAPI";

export const PAYMENT_VIEW_SCREEN_ENUMS = {
  SelectAccountsForPayment: "selectAccountsForPayment",
  SetupPaymentPlanConfiguration: "setupPaymentPlanConfiguration",
  SetupFullPayment: "setupFullPayment",
  SetupPartialPayment: "setupPartialPayment",
  // NOTE: These currently point to the same component, but we need the separation to properly keep track of previous and next steps.
  SelectPaymentMethod: "selectPaymentMethod",
  SelectPaymentOption: "selectPaymentOption",
  PaymentConfirmation: "paymentConfirmation",
};

export const initialState = {
  // Shared state fields
  // ------------------------------
  selectedAccounts: [],
  convenienceFee: null,
  isInterestApplicable: false, // If any account accrues interest, this will be set to true. This is used to determine if we need to call the backend to recalculate the balance when dates/amounts are paid.
  originalTotalAmount: null, // amount due across selected accounts
  // totalAmountDueAsOfStartDateWithInterest is the amount due across selected accounts accounting for interest based off the start date.
  // This value is calculated by the sum of the calculate balance history response from balanceResponse.
  totalAmountDueAsOfStartDateWithInterest: null,
  partialPaymentAmount: null, // If a partial payment is flagged, this will be non-empty
  totalPaymentAmount: null, // The total payment amount.
  isPaymentPlan: false, // defaults to one time payment, else it's a payment plan
  isNewPaymentMethod: true,
  recurringPaymentStartDate: moment().startOf("day").format(DATE_FORMAT), // Start of first payment. For one time payments, will be set to the payment date.

  // One Time Payments state fields
  // ------------------------------

  // full - Payment covers all selected account balances.
  // partial - Payment was made, but not the entire full amount.
  // settled - Payment covers all selected account balance, but payment is a settled amount (less than the full).
  paymentIntentType: "full", // [full, partial, settled]

  // Payment Plan state fields
  // ------------------------------
  numberOfPayments: 1, // Only applicable if it's a payment plan
  recurringPaymentInterval: "monthly", // ["monthly", "weekly, biweekly", "bimonthly"]
  recurringPaymentAmount: null,
  downPaymentAmount: 0,

  // NOTE: The paymentsSchedule is a list of payments that will be made.
  // [payment.index]: {
  //   totalAmount: payment.totalAmount,
  //   date: payment.date,
  // },
  paymentsSchedule: [],
  // Always hardcoded to false for Consumer Portal, as consumer doesn't get to select themselves
  // whether or not they want the remainder included.
  isIncludeRemainderToLastPayment: false,

  balanceResponse: undefined, // The response from the backend when we calculate the account balance which includes interest from the payment schedule.

  // Payment Method Screen
  paymentMethodOptions: undefined,
  currentView: PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment, // Defaults to first view in the Payments tab.
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    // Shared Reducers
    // ------------------------------
    setSelectedAccounts: (state, action) => {
      state.selectedAccounts = action.payload;
      state.isInterestApplicable = state.selectedAccounts.some(
        (account) => account.interestRate > 0,
      );
      state.originalTotalAmount = state.selectedAccounts.reduce(
        (total, account) => total.add(account.totalBalance),
        currency(0, { precision: 2 }),
      ).value;
    },
    setConvenienceFee: (state, action) => {
      state.convenienceFee = action.payload;
    },

    // One Time Payment Reducers
    // ------------------------------
    setAsOneTimeFullPayment: (state, action) => {
      const { paymentIntentType } = action.payload;
      state.isPaymentPlan = false;
      state.paymentIntentType = paymentIntentType;
    },
    setupAsOneTimePayment: (state, action) => {
      const { paymentIntentType, partialPaymentAmount, originalTotalAmount, totalPaymentAmount } =
        action.payload;
      state.paymentIntentType = paymentIntentType;
      if (paymentIntentType === "full") {
        state.partialPaymentAmount = null;
      } else {
        state.partialPaymentAmount = partialPaymentAmount;
      }
      state.convenienceFee = null;
      state.isPaymentPlan = false;
      state.originalTotalAmount = originalTotalAmount;
      state.totalPaymentAmount = totalPaymentAmount;
      // Clear out any values that are only used for Payment Plans.
      state.downPaymentAmount = null;
    },

    // Payment Plan Reducers
    // ------------------------------
    setupAsInitialPaymentPlan: (state, action) => {
      // Set the payment configuration mode to payment plan, and reset all the defaults.
      // NOTE: This is different from setPaymentPlanConfiguration, in that setPaymentPlanConfiguration should
      // be used if any of the variables change. This is only used to set the initial defaults.
      const {
        originalTotalAmount,
        totalPaymentAmount,
        numberOfPayments,
        recurringPaymentAmount,
        recurringPaymentInterval,
        recurringPaymentStartDate,
        paymentsSchedule,
      } = action.payload;
      // Reset payment plan.
      state.convenienceFee = 0;
      state.isPaymentPlan = true;
      state.downPaymentAmount = 0;
      state.partialPaymentAmount = null;

      // Set the initial payment plan defaults.
      state.originalTotalAmount = originalTotalAmount;
      state.totalAmountDueAsOfStartDateWithInterest = originalTotalAmount;
      state.totalPaymentAmount = totalPaymentAmount;
      state.numberOfPayments = numberOfPayments;
      state.recurringPaymentAmount = recurringPaymentAmount;
      state.recurringPaymentInterval = recurringPaymentInterval;
      state.recurringPaymentStartDate = moment(recurringPaymentStartDate).format(DATE_FORMAT);
      state.paymentsSchedule = paymentsSchedule;
    },
    setPaymentPlanConfiguration: (state, action) => {
      // NOTE: This is different from setPaymentPlanConfiguration, in that setPaymentPlanConfiguration should
      // be used if any of the variables change. setupAsInitialPaymentPlan is only used to set the initial defaults.
      const {
        recurringPaymentAmount,
        recurringPaymentInterval,
        recurringPaymentStartDate,
        numberOfPayments,
        totalAmountDueAsOfStartDateWithInterest,
        paymentsSchedule,
      } = action.payload;
      state.isPaymentPlan = true; // Redundant, but adding this here.
      state.recurringPaymentAmount = recurringPaymentAmount;
      state.recurringPaymentInterval = recurringPaymentInterval;
      state.recurringPaymentStartDate = moment(recurringPaymentStartDate).format(DATE_FORMAT);
      state.numberOfPayments = numberOfPayments;
      // NOTE: We don't alter the totalPaymentAmount after the initial setupAsInitialPaymentPlan.
      // state.totalPaymentAmount = state.totalPaymentAmount;

      // The totalAmountDueAsOfStartDateWithInterest continues to change each time a new request is sent, bc
      // the balance may change due to the accrued interest.
      state.totalAmountDueAsOfStartDateWithInterest = totalAmountDueAsOfStartDateWithInterest;
      state.paymentsSchedule = paymentsSchedule;
    },
    setPaymentsSchedule: (state, action) => {
      const { paymentsSchedule, totalAmountDueAsOfStartDateWithInterest } = action.payload;
      state.paymentsSchedule = paymentsSchedule;
      state.recurringPaymentStartDate = state.paymentsSchedule[0].scheduledDate;
      state.totalPaymentAmount = state.paymentsSchedule.reduce(
        (total, payment) => total.add(payment.totalAmount),
        currency(0, { precision: 2 }),
      ).value;
      // If the amount due hasn't been affected by interest, then keep it the same value.
      // In general, whenever the current payment schedule is updated, the totalAmountDueAsOfStartDateWithInterest
      // will update if the interest changes to track the new total amount due as of the start date as to account for interest.
      // Thus, totalAmountDueAsOfStartDateWithInterest should always track the latest amount due.
      state.totalAmountDueAsOfStartDateWithInterest =
        totalAmountDueAsOfStartDateWithInterest ?? state.totalAmountDueAsOfStartDateWithInterest;
    },
    setCurrentView: (state, action) => {
      state.currentView = action.payload;
    },
    resetMakeAPayment: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      paymentsApi.endpoints.calculateBalanceFromSchedule.matchFulfilled,
      (state, { payload }) => {
        state.balanceResponse = payload.results;
      },
    );
  },
});

export const {
  setAsOneTimeFullPayment,
  setSelectedAccounts,
  setupAsOneTimePayment,
  setupAsInitialPaymentPlan,
  setPaymentPlanConfiguration,
  resetMakeAPayment,
  setPaymentsSchedule,
  setCurrentView,
  setConvenienceFee,
} = paymentsSlice.actions;

export const selectPaymentsSlice = (state) => ({
  ...state.payments,
  isSettlement: state.payments.paymentIntentType === "settlement",
  isPartialPayment: state.payments.paymentIntentType === "partial",
  isFullPayment: state.payments.paymentIntentType === "full",
});

export default paymentsSlice.reducer;
