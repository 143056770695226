import { BankOutlined, CreditCardOutlined, PieChartOutlined } from "@ant-design/icons";
import { Button, Card, Flex } from "antd";
import styled from "styled-components";

const StyledActiveCard = styled(Card)`
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  &.ant-card {
    min-width: 400px !important;
  }
  &:hover {
    box-shadow: #f5f5f5 0px 0px 2px inset;
  }
`;

const StyledCreditCardOutlined = styled(CreditCardOutlined)`
  padding: 0px 16px;
  // border: 1px solid #d9d9d9;
  font-size: 24px;
  margin-right: 8px;
`;

const StyledBankOutlined = styled(BankOutlined)`
  padding: 0px 16px;
  // border: 1px solid #d9d9d9;
  font-size: 24px;
  margin-right: 8px;
`;

const StyledPieChartOutlined = styled(PieChartOutlined)`
  padding: 0px 16px;
  // border: 1px solid #d9d9d9;
  font-size: 24px;
  margin-right: 8px;
`;

const StyledHeader = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledDiv = styled.div`
  color: gray;
`;

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  min-width: 200px;
`;

/*
    PaymentScheduleCard component
    Displays a card with selector of Full / Partial / Payment Plan
    @param {function} onSelect - function to call when card is selected
    @param {string} type - type of payment schedule, one of "full", "partial", "plan"

    @returns {JSX.Element}
*/
export default function PaymentScheduleCard({ onSelect, type }) {
  return (
    <StyledActiveCard
    // @ts-ignore
    >
      <Flex justify="space-between" align="center" onClick={() => onSelect(type)}>
        <StyledFlex align="center" justify="space-between">
          <Flex>
            {type === "full" && <StyledCreditCardOutlined />}
            {type === "partial" && <StyledPieChartOutlined />}
            {type === "plan" && <StyledBankOutlined />}
            {type === "full" && (
              <div>
                <StyledHeader>Full Payment</StyledHeader>
                <StyledDiv>Pay your balance in full with a single payment. This is</StyledDiv>
                <StyledDiv>a quick and easy way to pay off your current balance.</StyledDiv>
              </div>
            )}
            {type === "partial" && (
              <div>
                <StyledHeader>Partial Payment</StyledHeader>
                <StyledDiv>Make a single payment towards your balance.</StyledDiv>
              </div>
            )}
            {type === "plan" && (
              <div>
                <StyledHeader>Payment Plan</StyledHeader>
                <StyledDiv>Create a payment plan to pay your balance over time.</StyledDiv>
              </div>
            )}
          </Flex>
          {type === "full" && (
            <StyledButton size="large" type="primary">
              Pay in Full
            </StyledButton>
          )}
          {type === "partial" && (
            <StyledButton size="large" type="primary">
              Make Partial Payment
            </StyledButton>
          )}
          {type === "plan" && (
            <StyledButton size="large" type="primary">
              Create Payment Plan
            </StyledButton>
          )}
        </StyledFlex>
      </Flex>
    </StyledActiveCard>
  );
}
