import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Form, Input, message } from "antd";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { resetStateAction } from "common/redux/resetStateAction";
import { useLazyFetchMeQuery, useLoginMutation } from "features/auth/authAPI";
import { selectIsAccessTokenStoredInRtk, updateTokens } from "features/auth/authSlice";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  width: 400px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;
`;

const StyledDiv = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const StyledHeading = styled.h2`
  margin-top: 0;
  text-align: center;
`;

const StyledCol = styled(Col)`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 48px;
  background: #fff;
  border-radius: 10px;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100% - 90px);
  position: absolute;
  background: #f7f7f7;
  padding-bottom: 24px;
`;

const StyledOutsideLink = styled.div`
  margin-top: 28px;
  text-align: center;
  & a {
    color: #0098ed;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    font-size: 14px;
  }
`;

const StyledLoginDisclosure = styled.div`
  color: gray;
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
  width: 400px;
`;

const BAD_REDIRECT_ROUTES = ["/login", "/signup", "/change-password"];

const DEFAULT_LOGIN_REDIRECT_SCREEN = "accounts";

function LocalLoginForm() {
  const { isWhiteLabeled, displayName } = useWhiteLabel();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const location = useLocation();
  const [fetchMe] = useLazyFetchMeQuery();
  const isAccessTokenStoredInRtk = useSelector(selectIsAccessTokenStoredInRtk);

  // Reset state when user navigates to login page
  useEffect(() => {
    if (isAccessTokenStoredInRtk) {
      dispatch(resetStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectToDestination = async () => {
    // Fetch the optional defaultLoginRedirectScreen field from /me
    // to see if there is a specific page a user should see
    // on login
    const { data: me } = await fetchMe();
    if ("error" in me) {
      form.setFields(formatError(me.error));
      setErrorMessage("Something went wrong");
    }

    // @ts-ignore
    const defaultLoginRedirectScreen = `/${
      me?.defaultLoginRedirectScreen || DEFAULT_LOGIN_REDIRECT_SCREEN
    }`;

    // Firstly, we look at returnTo object
    // Then, at defaultLoginRedirectScreen from fetchMe request
    // Lastly, we fall for this default value
    const returnTo =
      location.state?.returnTo && BAD_REDIRECT_ROUTES.indexOf(location.state.returnTo) === -1
        ? location.state.returnTo
        : defaultLoginRedirectScreen;

    navigate(returnTo);
  };

  const onFinish = async (values) => {
    const { email, password } = values;

    const result = await login({ email, password });
    if ("data" in result) {
      dispatch(updateTokens(result.data));
      await redirectToDestination();
      message.success("Logged in successfully.", 2);
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
      setErrorMessage("Invalid username or password");
    }
  };

  return (
    <StyledContainer>
      <StyledCol>
        <StyledHeading>Sign in to your account</StyledHeading>
        <StyledForm
          onFinish={onFinish}
          className="login-form"
          initialValues={{
            email: "",
            password: "",
          }}
          form={form}
        >
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              { required: true, message: "Please enter email" },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" data-testid="email" />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="password"
            rules={[{ required: true, message: "Please enter password" }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
              data-testid="password"
            />
          </Form.Item>
          <Form.Item>
            <StyledButton
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-testid="login-button"
              loading={isLoading}
            >
              Log in
            </StyledButton>
          </Form.Item>
          {errorMessage && (
            <Form.Item>
              <Alert message={errorMessage} type="error" />
            </Form.Item>
          )}
        </StyledForm>
        <StyledDiv>
          Forgot your{" "}
          <Link to="/forgot-email" state={{ isForgotEmail: true }}>
            login email
          </Link>{" "}
          or <Link to="/forgot-password">password</Link>?
        </StyledDiv>
      </StyledCol>
      <StyledOutsideLink>
        <Link to="/signup">Don't have an account? Sign up</Link>
      </StyledOutsideLink>
      <StyledLoginDisclosure>
        This communication is from a debt collector. This is an attempt to collect a debt. Any
        information obtained will be used for that purpose.
      </StyledLoginDisclosure>
    </StyledContainer>
  );
}

export default LocalLoginForm;
