import { DATE_FORMAT } from "common/constants";
import currency from "currency.js";
import moment from "moment-timezone";

export const getPaymentsScheduleForOneTimePayment = ({
  accountIds,
  accountUuids,
  paymentAmount,
  recurringPaymentStartDate = moment().format(DATE_FORMAT),
}) => [
  {
    index: 0,
    accountIds,
    accountUuids,
    title: "One Time Payment",
    scheduledDate: recurringPaymentStartDate,
    totalAmount: paymentAmount,
  },
];

export const getNextDate = ({
  recurringPaymentInterval,
  recurringPaymentStartDate,
  currentPaymentIndex = 0,
}) => {
  let currentDate = moment(recurringPaymentStartDate);
  if (currentPaymentIndex === 0) {
    return currentDate;
  }

  if (recurringPaymentInterval === "monthly") {
    currentDate = moment(recurringPaymentStartDate).add(currentPaymentIndex, "month");
  } else if (recurringPaymentInterval === "weekly") {
    currentDate = moment(recurringPaymentStartDate).add(currentPaymentIndex * 7, "days");
  } else if (recurringPaymentInterval === "biweekly") {
    currentDate = moment(recurringPaymentStartDate).add(currentPaymentIndex * 14, "days");
  } else if (recurringPaymentInterval === "bimonthly") {
    /* Iterating between 2 dates:
      some time in the first half of the month and in the second
      for example:
        4 payments
        StartDate of the plan: 5th of June.
        Payments
        June: 5th, 20th
        July 5th, 20th
      or:
        4 payments
        StartDate of the plan: 30th of January.
        Payments
        January: 30th
        February 15th, 28th
        March: 15th
    */
    const monthStep = Math.floor(currentPaymentIndex / 2);

    // .date() returns the day of the month, between 1 and 31. see more at https://momentjs.com/docs/#/get-set/date/
    const startDaySecondHalf = moment(recurringPaymentStartDate).date() > 15;
    const isSecondHalf =
      (currentPaymentIndex % 2 !== 0 && !startDaySecondHalf) ||
      (currentPaymentIndex % 2 === 0 && startDaySecondHalf);

    if (isSecondHalf) {
      if (startDaySecondHalf) {
        currentDate = moment(recurringPaymentStartDate).add(monthStep, "month");
      } else {
        currentDate = moment(recurringPaymentStartDate).add(monthStep, "month");

        const maxDaysInMonth = moment(currentDate).daysInMonth();
        const dayOftheMonth = moment(currentDate).date();

        if (dayOftheMonth + 15 > maxDaysInMonth) {
          currentDate = moment(currentDate).set("date", maxDaysInMonth);
        } else {
          currentDate = moment(currentDate).add(15, "days");
        }
      }
    } else if (startDaySecondHalf) {
      const dayOftheMonth = moment(currentDate).date();
      currentDate = moment(recurringPaymentStartDate)
        .add(monthStep + 1, "month")
        .date(dayOftheMonth - 15);
    } else {
      currentDate = moment(recurringPaymentStartDate).add(monthStep, "month");
    }
  }
  return currentDate;
};

export const convertPaymentsScheduleToPaymentIntents = ({ paymentsSchedule }) => {
  const paymentIntentsByDateMap = {};
  paymentsSchedule.forEach((payment) => {
    const currentPaymentIntent = paymentIntentsByDateMap[payment.scheduledDate] || {};
    currentPaymentIntent.scheduledDate = payment.scheduledDate;
    currentPaymentIntent.totalAmount = payment.totalAmount;
    currentPaymentIntent.accountIds = payment.accountIds;
    currentPaymentIntent.accountUuids = payment.accountuuids;
    paymentIntentsByDateMap[payment.scheduledDate] = currentPaymentIntent;
  });
  console.log("paymentIntentsByDateMap: ", paymentIntentsByDateMap);
  return paymentIntentsByDateMap;
};

export const getPaymentIntents = ({
  paymentMethodId,
  referenceNumber,
  paymentsSchedule,
  paymentIntentType,
  isPaymentPlan,
  promiseToPayDate,
  promiseToPayLastDate,
}) => {
  const paymentIntentsByDateMap = convertPaymentsScheduleToPaymentIntents({ paymentsSchedule });
  const byDateMapKeys = Object.keys(paymentIntentsByDateMap);
  const paymentIntents = byDateMapKeys.map((paymentDate, index) => {
    const isLast = index === byDateMapKeys.length - 1;
    return {
      ...paymentIntentsByDateMap[paymentDate],
      paymentMethodId,
      paymentIntentType: isLast ? paymentIntentType : "partial",
      isPaymentPlan,
      referenceNumber,
      promiseToPayDate,
      promiseToPayLastDate,
    };
  });
  console.log("paymentIntents: ", paymentIntents);
  return paymentIntents;
};

export const getPaymentsScheduleInfoWithInterest = async ({
  isInterestApplicable,
  paymentsScheduleWithoutInterest,
  totalAmountDueAsOfStartDateWithoutInterest,
  calculateBalanceFromSchedule,
}) => {
  if (!isInterestApplicable) {
    // No interest, so no need to recalculate remaining balance.
    return {
      paymentsSchedule: paymentsScheduleWithoutInterest,
      // The totalAmountDueAsOfStartDateWithInterest should only be set to null
      // in the initial defaults of the payment option.
      // Subsequent amount/payment-date changes should not be calculated off
      // the paymentsSchedule, and passed in by the caller, because the paymentsSchedule
      // does not represent all payments required to fully pay off the account except in the
      // full payment scenario (e.g partial payment, settlements).
      totalAmountDueAsOfStartDateWithInterest:
        totalAmountDueAsOfStartDateWithoutInterest ??
        paymentsScheduleWithoutInterest.reduce(
          (total, payment) => total.add(payment.totalAmount),
          currency(0, { precision: 2 }),
        ).value,
    };
  }
  const paymentsScheduleWithInterest = [...paymentsScheduleWithoutInterest];
  // Zero out the last payment amount so backend can project with interest.
  paymentsScheduleWithInterest[paymentsScheduleWithInterest.length - 1] = {
    ...paymentsScheduleWithInterest[paymentsScheduleWithInterest.length - 1],
    totalAmount: 0,
  };

  const balance = await calculateBalanceFromSchedule({
    intents: paymentsScheduleWithInterest.map((payment) => ({
      ...payment,
      totalAmount: payment.totalAmount ?? 0,
      scheduledDate: payment.scheduledDate,
    })),
  });

  // We need to extract the last payment total and add it to the last payment
  paymentsScheduleWithInterest[paymentsScheduleWithInterest.length - 1] = {
    ...paymentsScheduleWithInterest[paymentsScheduleWithInterest.length - 1],
    // @ts-ignore
    totalAmount: currency(
      balance.data.summary[balance.data.summary.length - 1].remainingBalanceTotal,
      {
        precision: 2,
      },
    ).value,
  };

  const totalAmountDueAsOfStartDateWithInterest = paymentsScheduleWithInterest.reduce(
    (total, payment) => total.add(payment.totalAmount),
    currency(0, { precision: 2 }),
  ).value;

  return {
    paymentsSchedule: paymentsScheduleWithInterest,
    totalAmountDueAsOfStartDateWithInterest,
  };
};
