import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Layout } from "antd";
import Header from "layouts/consumerPortal/header";
import AppMenu from "layouts/consumerPortal/menu";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const StyledContent = styled(Layout.Content)`
  padding: 8px 24px 24px 24px;
  height: calc(100vh - 64px);
  margin-top: 64px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const StyledDisclosure = styled.div`
  color: gray;
  bottom: 12px;
  font-size: 11px;
  margin-top: auto;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: gray;
  font-size: 11px;
`;

function PageLayout() {
  return (
    <Layout>
      <Header />
      <Layout>
        <AppMenu />
        <StyledContent>
          <Outlet />
          <StyledDisclosure>
            <StyledExclamationCircleOutlined /> This communication is from a debt collector. This is
            an attempt to collect a debt. Any information obtained will be used for that purpose.
            Making a payment today does not overshadow the rights provided to you in your initial
            notice.
          </StyledDisclosure>
        </StyledContent>
      </Layout>
    </Layout>
  );
}

export default PageLayout;
