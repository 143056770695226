import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Flex, Form, Input, message, Row, Tooltip } from "antd";
import { DATE_FORMAT } from "common/constants";
import { formatCurrency, formatDate } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { useCreatePaymentIntentMutation } from "features/consumerPortalUpcomingPayments/paymentIntentsAPI";
import AddPaymentMethodForm from "features/consumerPortalPaymentMethods/components/addPaymentMethodForm";
import {
  useCalculateBalanceFromScheduleMutation,
  useCalculateConvenienceFeesMutation,
} from "features/consumerPortalPaymentPage/paymentsAPI";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setConvenienceFee,
  setCurrentView,
  setPaymentsSchedule,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getPaymentsScheduleForOneTimePayment,
  getPaymentsScheduleInfoWithInterest,
} from "features/payments/paymentDefaults";
import { useEffect, useState } from "react";
import { useGetPaymentMethodsQuery } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";

const StyledDiv = styled(Flex)`
  margin-top: 12px;
`;

const StyledValue = styled.span`
  overflow-wrap: anywhere;
  color: grey;
  font-size: 13px;
`;

const StyledPaymentSummaryCard = styled(Card)`
  min-width: 570px;
`;

const StyledLabel = styled.span`
  font-size: 13px;
  flex: 1;
`;

const StyledCard = styled(Card)`
  margin-bottom: 12px;
  width: 570px;
  margin-right: 12px;
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
`;

const StyledPayButton = styled(Button)`
  height: 48px;
`;

const StyledForm = styled(Form)`
  margin-top: 16px;
`;

const StyledDisclosure = styled.div`
  font-style: italic;
  color: gray;
  margin-top: 8px;
`;

function FullPayment() {
  const [form] = Form.useForm();
  const [processPayment, { isLoading: isPosting }] = useCreatePaymentIntentMutation();
  const dispatch = useDispatch();
  const [fetchConvenienceFees] = useCalculateConvenienceFeesMutation();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const [
    calculateBalanceFromSchedule,
    { isLoading: isCalculateBalanceFromScheduleLoading, error },
  ] = useCalculateBalanceFromScheduleMutation();
  const { data: paymentMethods = [], isLoading: isPaymentMethodsLoading } =
    useGetPaymentMethodsQuery();

  const changePaymentDate = async (scheduledDate) => {
    const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
      accountIds: null, // Consumer portal won't have account Ids, only UUIDs.
      accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
      paymentAmount: paymentsSlice.originalTotalAmount,
      recurringPaymentStartDate: scheduledDate,
    });

    const paymentsScheduleInfoWithInterest = await getPaymentsScheduleInfoWithInterest({
      isInterestApplicable: paymentsSlice.isInterestApplicable,
      paymentsScheduleWithoutInterest: paymentsSchedule,
      totalAmountDueAsOfStartDateWithoutInterest: paymentsSlice.isInterestApplicable
        ? paymentsSlice.totalAmountDueAsOfStartDateWithInterest
        : paymentsSlice.originalTotalAmount,
      calculateBalanceFromSchedule,
    });
    dispatch(
      setPaymentsSchedule({
        paymentsSchedule: paymentsScheduleInfoWithInterest.paymentsSchedule,
        totalAmountDueAsOfStartDateWithInterest:
          paymentsScheduleInfoWithInterest.totalAmountDueAsOfStartDateWithInterest,
      }),
    );
  };

  const [currentPaymentState, setCurrentPaymentState] = useState("");
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("");

  useEffect(() => {
    (async () => {
      // If the payment method is not selected or if existing payment is selected without a method selected,
      // then don't calculate the convenience fee.
      if (
        !currentPaymentMethod ||
        (currentPaymentMethod === "existingPaymentMethod" &&
          !form.getFieldValue("paymentMethodUuid"))
      ) {
        dispatch(setConvenienceFee(null));
        return;
      }

      // If no payment state is selected with card/ach, then don't calculate the convenience fee.
      if (
        !currentPaymentState &&
        (currentPaymentMethod === "process_card" || currentPaymentMethod === "process_ach")
      ) {
        dispatch(setConvenienceFee(null));
        return;
      }
      const result = await fetchConvenienceFees({
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        amounts: [paymentsSlice.paymentsSchedule[0].totalAmount],
        state:
          currentPaymentMethod !== "process_card" && currentPaymentMethod !== "process_ach"
            ? paymentMethods.find((each) => each.uuid === form.getFieldValue("paymentMethodUuid"))
                ?.state
            : currentPaymentState,
        paymentMethodType:
          currentPaymentMethod !== "process_card" && currentPaymentMethod !== "process_ach"
            ? paymentMethods.find((each) => each.uuid === form.getFieldValue("paymentMethodUuid"))
                ?.type
            : currentPaymentMethod,
      });
      if ("data" in result) {
        dispatch(setConvenienceFee(result.data.convenienceFees[0]));
      }
    })();
  }, [
    dispatch,
    form,
    fetchConvenienceFees,
    paymentsSlice.selectedAccounts,
    paymentMethods,
    paymentsSlice.paymentsSchedule,
    currentPaymentState,
    currentPaymentMethod,
  ]);

  const onFinish = async ({ scheduledDate, paymentMethodUuid, newPaymentMethod }) => {
    const intents = [
      {
        totalAmount: paymentsSlice.totalPaymentAmount,
        scheduledDate,
        paymentMethodUuid,
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        paymentIntentType: "full",
        isPaymentPlan: false,
      },
    ];
    const newPaymentMethodFields = {};
    if (!paymentMethodUuid) {
      const { paymentMethod, ...newPaymentMethodValues } = newPaymentMethod;
      const [firstName, lastName] = (newPaymentMethodValues.name ?? "").split(" ");
      newPaymentMethodValues.firstName = firstName;
      newPaymentMethodValues.lastName = lastName;

      if (paymentMethod === "process_card") {
        // post-transformation for credit card
        newPaymentMethodValues.cardExpMonth = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("MM");
        newPaymentMethodValues.cardExpYear = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("YYYY");
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
      if (paymentMethod === "process_ach") {
        newPaymentMethodFields.newAch = newPaymentMethodValues;
      }
    }
    const result = await processPayment({
      intents,
      ...newPaymentMethodFields,
      paymentIntentType: "full",
      paymentMethodUuid,
      executeIfReady: true,
      paymentMethodType: paymentMethodUuid ? null : newPaymentMethod.paymentMethod,
    });
    if ("data" in result) {
      dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.PaymentConfirmation));
    }
    if ("error" in result) {
      message.error(result.error.data?.error?.message || "Server error: Failed to process payment");
    }
  };

  const disabledDate = (current) => {
    return (current && current >= moment().add(30, "day")) || current < moment().startOf("day");
  };

  const initialValues = {
    totalPaymentAmount: paymentsSlice.totalPaymentAmount,
    scheduledDate: moment().format(DATE_FORMAT),
    isNewPaymentMethod: false,
    newPaymentMethod: {
      // paymentMethod: "process_card",
    },
  };

  const onPrevious = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentOption));
  };

  return (
    <StyledForm form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
      <Flex>
        <Col>
          <StyledCard title="Payment Date">
            <Form.Item
              label="Scheduled Date"
              name="scheduledDate"
              rules={[{ required: true, message: "Please select the payment date" }]}
            >
              <AktDatePicker onChange={changePaymentDate} type="date" disabledDate={disabledDate} />
            </Form.Item>
          </StyledCard>
          <StyledCard title="Contact">
            <Form.Item
              name="email"
              label="Email"
              tooltip="Payment receipts will be sent to this email address."
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>
          </StyledCard>
          <StyledCard title="Payment Information">
            <AddPaymentMethodForm
              prefix="newPaymentMethod"
              onStateChange={setCurrentPaymentState}
              onPaymentMethodChange={setCurrentPaymentMethod}
            />
          </StyledCard>
        </Col>
        <Col>
          <StyledPaymentSummaryCard
            loading={isCalculateBalanceFromScheduleLoading}
            title="Payment Summary"
          >
            {paymentsSlice.selectedAccounts?.map((selectedAccount) => {
              return (
                <>
                  <Row align="middle">
                    <StyledLabel>Account Number</StyledLabel>
                    <StyledValue>{selectedAccount.externalId}</StyledValue>
                  </Row>
                  <Row align="middle">
                    <StyledLabel>Creditor Name</StyledLabel>
                    <StyledValue>{selectedAccount.currentCreditorName}</StyledValue>
                  </Row>
                  <Row align="middle">
                    <StyledLabel>
                      Original Balance{" "}
                      <Tooltip title={`Balance due as of ${moment().format(DATE_FORMAT)}`}>
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </StyledLabel>
                    <StyledValue>{formatCurrency(selectedAccount.totalBalance)}</StyledValue>
                  </Row>
                  <Row align="middle">
                    <StyledLabel>Interest Rate</StyledLabel>
                    <StyledValue>{`${
                      currency(selectedAccount.interestRate, { precision: 4 }).multiply(100).value
                    }%`}</StyledValue>
                  </Row>
                  <br />
                </>
              );
            })}
            <Divider />
            <Row align="middle">
              <StyledLabel>Balance as of {paymentsSlice.recurringPaymentStartDate}</StyledLabel>
              <StyledValue>
                {formatCurrency(currency(paymentsSlice.totalPaymentAmount))}
              </StyledValue>
            </Row>
            <Row align="middle">
              <StyledLabel>
                Convenience Fee{" "}
                <Tooltip title="The convenience fee is calculated based on the state, payment method, and the payment amount">
                  <QuestionCircleOutlined />
                </Tooltip>
              </StyledLabel>
              <StyledValue>
                {paymentsSlice.convenienceFee?.fee === null
                  ? "(to be calculated)"
                  : formatCurrency(paymentsSlice.convenienceFee?.fee)}
              </StyledValue>
            </Row>
            <Row align="middle">
              <StyledLabel>Total Amount Due </StyledLabel>
              <StyledValue>
                {formatCurrency(
                  currency(paymentsSlice.totalPaymentAmount).add(paymentsSlice.convenienceFee?.fee),
                )}
              </StyledValue>
            </Row>
          </StyledPaymentSummaryCard>
          <StyledDiv>
            <StyledButton icon={<ArrowLeftOutlined />} type="link" onClick={onPrevious}>
              Go Back
            </StyledButton>
            <StyledPayButton
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={isPosting}
            >
              {paymentsSlice.recurringPaymentStartDate === formatDate(moment().startOf("day"))
                ? `Pay ${formatCurrency(
                    currency(paymentsSlice.totalPaymentAmount).add(
                      paymentsSlice.convenienceFee?.fee,
                    ),
                  )}`
                : `Schedule Payment (${formatCurrency(
                    currency(paymentsSlice.totalPaymentAmount).add(
                      paymentsSlice.convenienceFee?.fee,
                    ),
                  )})`}
            </StyledPayButton>
          </StyledDiv>
          <StyledDisclosure>
            Attention:{" "}
            {paymentsSlice.convenienceFee?.message ||
              "You are authorizing the payment service provider to charge your payment method for the principal amount and a convenience fee. For ACH payments, this will appear on your statement as two separate transactions. For card payments, this will appear as one transaction. You understand that you are paying a fee for the convenience of making this payment by phone and that you can avoid this fee by mailing a check or money order to our office."}
          </StyledDisclosure>
        </Col>
      </Flex>
    </StyledForm>
  );
}

export default FullPayment;
