import api, { transformRequest, transformResponse } from "services/api";

export const paymentsApi = api
  .enhanceEndpoints({ addTagTypes: ["ConsumerPortalPaymentMethods"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getPaymentMethods: build.query({
        query: () => `/api/v1/consumer/payment-methods/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "ConsumerPortalPaymentMethods", id: "LIST" }],
      }),
      createCardPaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-methods/create-card-from-number/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ConsumerPortalPaymentMethods", id: "LIST" }],
      }),
      createAchPaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-methods/create-ach-from-number/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ConsumerPortalPaymentMethods", id: "LIST" }],
      }),
      deletePaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-methods/${payload.paymentMethodUuid}/delete/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        invalidatesTags: [{ type: "ConsumerPortalPaymentMethods", id: "LIST" }],
      }),
      archivePaymentMethod: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-methods/${payload.paymentMethodUuid}/archive/`,
          method: "POST",
          body: transformRequest({
            delete_scheduled_payment_intents: payload.deleteScheduledPaymentIntents,
          }),
        }),
        invalidatesTags: [{ type: "ConsumerPortalPaymentMethods", id: "LIST" }],
      }),
    }),
    overrideExisting: false,
  });

export const {
  useCreateAchPaymentMethodMutation,
  useCreateCardPaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useArchivePaymentMethodMutation,
} = paymentsApi;
