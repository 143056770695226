import { ArrowLeftOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Steps,
  Table,
  Tooltip,
} from "antd";
import { DATE_FORMAT } from "common/constants";
import { capitalizeFirstLetter, debounce, formatCurrency, formatDate } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { useCreatePaymentIntentMutation } from "features/consumerPortalUpcomingPayments/paymentIntentsAPI";
import AddPaymentMethodForm from "features/consumerPortalPaymentMethods/components/addPaymentMethodForm";
import {
  useBulkCreatePaymentIntentsMutation,
  useCalculateConvenienceFeesMutation,
} from "features/consumerPortalPaymentPage/paymentsAPI";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useGetPaymentMethodsQuery } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setConvenienceFee,
  setCurrentView,
  setPaymentPlanConfiguration,
} from "features/consumerPortalPaymentPage/paymentsSlice";

const StyledCard = styled(Card)`
  margin-bottom: 12px;
  margin-right: 12px;
  width: 570px;
  .ant-card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-card-head {
    border-bottom: none;
  }
`;

const StyledPaymentSummaryCard = styled(Card)`
  min-width: 570px;
  min-height: 380px;
`;

const StyledButton = styled(Button)`
  margin-top: 8px;
`;

const StyledDiv = styled(Flex)`
  margin-top: 12px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 220px;
`;

const StyledScheduleButton = styled(Button)`
  padding: 0;
  font-size: 13px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;

const StyledValue = styled.span`
  overflow-wrap: anywhere;
  font-size: 13px;
  color: grey;
`;

const StyledLabel = styled.span`
  font-size: 13px;
  flex: 1;
`;

const StyledPayButton = styled(Button)`
  height: 48px;
`;

const StyledForm = styled(Form)`
  margin-top: 16px;
`;

const StyledDisclosure = styled.div`
  font-style: italic;
  color: gray;
  margin-top: 8px;
`;

const StyledButtonLink = styled(Button)`
  color: #0098ed;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0;
  margin: 0;
  height: auto;
  line-height: 1;
`;

function PaymentPlan() {
  const [form] = Form.useForm();
  const [processPayment, { isLoading: isPosting }] = useCreatePaymentIntentMutation();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const [isPlanPreviewModalVisible, setPlanPreviewModalVisible] = useState(false);
  const [fetchConvenienceFees] = useCalculateConvenienceFeesMutation();
  const [bulkCreatePaymentIntents, { isLoading: isBulkCreatePaymentIntentsLoading, error }] =
    useBulkCreatePaymentIntentsMutation();
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useGetPaymentMethodsQuery();

  const [currentPaymentState, setCurrentPaymentState] = useState("");
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("");
  const [isTermsConditionsChecked, setIsTermsConditionsChecked] = useState(false);
  const [isConvenienceDisclosureChecked, setIsConvenienceDisclosureChecked] = useState(false);

  const handleBulkCreatePaymentIntentsResponse = async (result) => {
    if ("data" in result) {
      const numberOfPayments = result.data?.numberOfRecurringPayments;
      const recurringPaymentAmount = result.data?.firstRecurringPaymentIntentData?.totalAmount;
      const recurringPaymentStartDate = result.data?.firstRecurringPaymentIntentData?.scheduledDate;
      const recurringPaymentInterval = result.data?.recurringPaymentInterval;
      const totalAmountDueAsOfStartDateWithInterest = result.data?.totalAmount;
      const paymentsSchedule = result.data?.paymentIntentsData;

      await dispatch(
        setPaymentPlanConfiguration({
          recurringPaymentAmount,
          recurringPaymentInterval,
          recurringPaymentStartDate,
          numberOfPayments,
          totalAmountDueAsOfStartDateWithInterest,
          paymentsSchedule,
        }),
      );

      // NOTE: This does not trigger the on-change handlers for both the recurringPaymentAmount and numberOfPayments:
      // onChangeRecurringPaymentAmount and onNumberOfPaymentsChange
      form.setFieldsValue({
        recurringPaymentAmount,
        numberOfPayments,
      });
    }
  };

  useEffect(() => {
    (async () => {
      // If the payment method is not selected or if existing payment is selected without a method selected,
      // then don't calculate the convenience fee.
      if (
        !currentPaymentMethod ||
        (currentPaymentMethod === "existingPaymentMethod" &&
          !form.getFieldValue("paymentMethodUuid"))
      ) {
        dispatch(setConvenienceFee(null));
        return;
      }

      // If no payment state is selected with card/ach, then don't calculate the convenience fee.
      if (
        !currentPaymentState &&
        (currentPaymentMethod === "process_card" || currentPaymentMethod === "process_ach")
      ) {
        dispatch(setConvenienceFee(null));
        return;
      }
      const result = await fetchConvenienceFees({
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        amounts: [paymentsSlice.paymentsSchedule[0].totalAmount],
        state:
          currentPaymentMethod !== "process_card" && currentPaymentMethod !== "process_ach"
            ? paymentMethods.find((each) => each.uuid === form.getFieldValue("paymentMethodUuid"))
                ?.state
            : currentPaymentState,
        paymentMethodType:
          currentPaymentMethod !== "process_card" && currentPaymentMethod !== "process_ach"
            ? paymentMethods.find((each) => each.uuid === form.getFieldValue("paymentMethodUuid"))
                ?.type
            : currentPaymentMethod,
      });
      if ("data" in result) {
        dispatch(setConvenienceFee(result.data.convenienceFees[0]));
      }
    })();
  }, [
    dispatch,
    form,
    fetchConvenienceFees,
    paymentsSlice.selectedAccounts,
    paymentMethods,
    paymentsSlice.paymentsSchedule,
    currentPaymentState,
    currentPaymentMethod,
  ]);

  const changePaymentDate = async (newPaymentDate) => {
    const result = await bulkCreatePaymentIntents({
      accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
      recurringPaymentAmount: paymentsSlice.recurringPaymentAmount,
      recurringPaymentStartDate: newPaymentDate,
      recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
      paymentIntentType: "full",
      totalAmount: paymentsSlice.totalPaymentAmount,
      // Always hardcoded to false for Consumer Portal, as consumer doesn't get to select themselves
      // whether or not they want the remainder included.
      isIncludeRemainderToLastPayment: false,
    });

    await handleBulkCreatePaymentIntentsResponse(result);
  };

  const onRecurringPaymentIntervalChange = async (newRecurringPaymentInterval) => {
    const result = await bulkCreatePaymentIntents({
      accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
      recurringPaymentAmount: paymentsSlice.recurringPaymentAmount,
      recurringPaymentStartDate: paymentsSlice.recurringPaymentStartDate,
      recurringPaymentInterval: newRecurringPaymentInterval,
      paymentIntentType: "full",
      totalAmount: paymentsSlice.totalPaymentAmount,
      // Always hardcoded to false for Consumer Portal, as consumer doesn't get to select themselves
      // whether or not they want the remainder included.
      isIncludeRemainderToLastPayment: false,
    });

    await handleBulkCreatePaymentIntentsResponse(result);
  };

  const onChangeRecurringAmount = debounce(async (newRecurringAmount) => {
    const { originalTotalAmount } = paymentsSlice;
    if (newRecurringAmount === null) {
      return;
    }

    // TODO: This is wrong, should take into account interest.
    if (newRecurringAmount > originalTotalAmount) {
      return;
    }

    const result = await bulkCreatePaymentIntents({
      accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
      recurringPaymentAmount: newRecurringAmount,
      recurringPaymentStartDate: paymentsSlice.recurringPaymentStartDate,
      recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
      paymentIntentType: "full",
      totalAmount: paymentsSlice.totalPaymentAmount,
      // Always hardcoded to false for Consumer Portal, as consumer doesn't get to select themselves
      // whether or not they want the remainder included.
      isIncludeRemainderToLastPayment: false,
    });

    await handleBulkCreatePaymentIntentsResponse(result);
  }, 1000);

  const steps = [
    {
      title: "E-Sign Terms & Conditions",
      content: (
        <>
          <p>
            Please read and review the{" "}
            <StyledButtonLink
              target="_blank"
              rel="noopener noreferrer"
              type="link"
              href="https://www.aktos.ai/esignterms"
            >
              E-Sign Terms and Conditions Disclosure
            </StyledButtonLink>
            .
          </p>
          <p>
            <Checkbox onChange={(e) => setIsTermsConditionsChecked(e.target.checked)}>
              By checking this box, you are acknowledging and agreeing to our terms and conditions{" "}
            </Checkbox>
          </p>
        </>
      ),
    },
    {
      title: "Convenience Fee Disclosure",
      content: (
        <>
          <p>
            Attention:{" "}
            {paymentsSlice.convenienceFee?.message ||
              "You are authorizing the payment service provider to charge your payment method for the principal amount and a convenience fee. For ACH payments, this will appear on your statement as two separate transactions. For card payments, this will appear as one transaction. You understand that you are paying a fee for the convenience of making this payment by phone and that you can avoid this fee by mailing a check or money order to our office."}
          </p>
          <p>
            <strong>Convenience Fee: {formatCurrency(paymentsSlice.convenienceFee?.fee)}</strong>
          </p>
          <p>
            <Checkbox onChange={(e) => setIsConvenienceDisclosureChecked(e.target.checked)}>
              By checking this box, you are acknowledging and agreeing to our terms and conditions{" "}
            </Checkbox>
          </p>
        </>
      ),
    },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [showDisclosureModal, setShowDisclosureModal] = useState(false);

  const onFinish = async ({ paymentMethodUuid, newPaymentMethod }) => {
    const intents = paymentsSlice.paymentsSchedule.map((intent) => ({
      ...intent,
      paymentMethodUuid,
    }));

    const newPaymentMethodFields = {};
    if (!paymentMethodUuid) {
      const { paymentMethod, ...newPaymentMethodValues } = newPaymentMethod;
      const [firstName, lastName] = (newPaymentMethodValues.name ?? "").split(" ");
      newPaymentMethodValues.firstName = firstName;
      newPaymentMethodValues.lastName = lastName;

      if (paymentMethod === "process_card") {
        // post-transformation for credit card
        newPaymentMethodValues.cardExpMonth = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("MM");
        newPaymentMethodValues.cardExpYear = moment(
          newPaymentMethodValues.cardExpDate,
          "MM/YY",
        ).format("YYYY");
        newPaymentMethodFields.newCreditCard = newPaymentMethodValues;
      }
      if (paymentMethod === "process_ach") {
        newPaymentMethodFields.newAch = newPaymentMethodValues;
      }
    }
    try {
      const result = await processPayment({
        intents,
        ...newPaymentMethodFields,
        newPaymentMethod,
        paymentIntentType: "full",
        paymentMethodUuid,
        paymentMethodType: paymentMethodUuid ? null : newPaymentMethod.paymentMethod,
        executeIfReady: true,
      });
      if ("data" in result) {
        dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.PaymentConfirmation));
      }
    } catch (err) {
      message.error("Server error: Failed to process payment");
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return (current && current >= moment().add(30, "day")) || current < moment().startOf("day");
  };

  const initialValues = {
    recurringAmount: paymentsSlice.totalPaymentAmount,
    frequency: paymentsSlice.recurringPaymentInterval,
    recurringPaymentStartDate: paymentsSlice.recurringPaymentStartDate,
    isNewPaymentMethod: false,
    newPaymentMethod: {
      // paymentMethod: "process_card") {,
    },
  };

  const onPrevious = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectPaymentOption));
  };

  return (
    <>
      <StyledForm form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <Flex>
          <Col>
            <StyledCard title="Set up Payment Plan">
              <Space>
                <Form.Item
                  label="Recurring Amount"
                  name="recurringAmount"
                  rules={[
                    { required: true, message: "Please enter an amount" },
                    {
                      type: "number",
                      max: paymentsSlice.isInterestApplicable
                        ? paymentsSlice.totalAmountDueAsOfStartDateWithInterest
                        : paymentsSlice.originalTotalAmount,
                      message: paymentsSlice.isInterestApplicable
                        ? `Please enter a maximum of $${paymentsSlice.totalAmountDueAsOfStartDateWithInterest}`
                        : `Please enter a maximum of $${paymentsSlice.originalTotalAmount}`,
                    },
                    {
                      type: "number",
                      min: 20,
                      message: "Please enter a minimum of $20.00",
                    },
                    {
                      required: paymentsSlice.numberOfPayments > 500,
                      message: "Cannot exceed 500 recurring payments",
                    },
                  ]}
                >
                  <StyledInputNumber
                    onChange={onChangeRecurringAmount}
                    placeholder="Enter a minimum of $20.00"
                    prefix="$"
                    precision={2}
                    controls={false}
                    defaultValue={0}
                    min={20}
                  />
                </Form.Item>
                {paymentsSlice.recurringPaymentAmount > 0 && (
                  <StyledValue>
                    Total of{" "}
                    {/*
                      If there's a remainder, and it's not included with the last payment,
                      make sure to include that.
                    */}
                    {paymentsSlice.numberOfPayments} recurring payment(s)
                  </StyledValue>
                )}
              </Space>
              <Form.Item
                label="Frequency"
                name="frequency"
                rules={[{ required: true, message: "Please select the frequency" }]}
              >
                <Select
                  placeholder="Select Frequency"
                  options={[
                    { label: "Monthly", value: "monthly" },
                    { label: "Bi-monthly", value: "bimonthly" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Bi-weekly", value: "biweekly" },
                  ]}
                  onChange={onRecurringPaymentIntervalChange}
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
              <Form.Item
                label="Plan Start Date"
                name="recurringPaymentStartDate"
                rules={[{ required: true, message: "Please select the payment date" }]}
              >
                <AktDatePicker
                  onChange={changePaymentDate}
                  type="date"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Contact">
              <Form.Item
                name="email"
                label="Email"
                tooltip="Payment receipts will be sent to this email address."
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input placeholder="Enter Email" />
              </Form.Item>
            </StyledCard>
            <StyledCard title="Payment Information">
              <AddPaymentMethodForm
                prefix="newPaymentMethod"
                onStateChange={setCurrentPaymentState}
                onPaymentMethodChange={setCurrentPaymentMethod}
              />
            </StyledCard>
          </Col>
          <Col>
            <StyledPaymentSummaryCard
              loading={isBulkCreatePaymentIntentsLoading}
              title="Payment Plan Summary"
            >
              {paymentsSlice.selectedAccounts?.map((selectedAccount, index) => {
                return (
                  <>
                    {index > 0 && <br />}
                    <Row align="middle">
                      <StyledLabel>Account Number</StyledLabel>
                      <StyledValue>{selectedAccount.externalId}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Creditor Name</StyledLabel>
                      <StyledValue>{selectedAccount.currentCreditorName}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>
                        Original Balance{" "}
                        <Tooltip title={`Balance due as of ${moment().format(DATE_FORMAT)}`}>
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </StyledLabel>
                      <StyledValue>{formatCurrency(selectedAccount.totalBalance)}</StyledValue>
                    </Row>
                    <Row align="middle">
                      <StyledLabel>Interest Rate</StyledLabel>
                      <StyledValue>{`${
                        currency(selectedAccount.interestRate, { precision: 4 }).multiply(100).value
                      }%`}</StyledValue>
                    </Row>
                    <br />
                  </>
                );
              })}
              <Divider />
              <Row align="middle">
                <StyledLabel>Recurring Amount</StyledLabel>
                <StyledValue>{formatCurrency(paymentsSlice.recurringPaymentAmount)}</StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel>Date of First Payment</StyledLabel>
                <StyledValue>{paymentsSlice.recurringPaymentStartDate}</StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel>Payment Frequency</StyledLabel>
                <StyledValue>
                  {capitalizeFirstLetter(paymentsSlice?.recurringPaymentInterval)}
                </StyledValue>
              </Row>
              <Row align="middle">
                <StyledLabel># of Payments</StyledLabel>
                <StyledValue>{paymentsSlice.numberOfPayments}</StyledValue>
              </Row>
              {paymentsSlice.paymentsSchedule?.[paymentsSlice.paymentsSchedule.length - 1]
                ?.totalAmount > 0 && (
                <Row align="middle">
                  <StyledLabel>Final Payment</StyledLabel>
                  <StyledValue>
                    {formatCurrency(
                      paymentsSlice.paymentsSchedule[paymentsSlice.paymentsSchedule.length - 1]
                        .totalAmount,
                    )}
                  </StyledValue>
                </Row>
              )}
              <Row align="middle">
                <StyledLabel>
                  Convenience Fee (per transaction){" "}
                  <Tooltip title="The convenience fee is calculated based on the state, payment method, and the payment amount">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </StyledLabel>
                <StyledValue>
                  {paymentsSlice.convenienceFee === null
                    ? "(to be calculated)"
                    : formatCurrency(paymentsSlice.convenienceFee?.fee)}
                </StyledValue>
              </Row>
              <Divider />

              <Row align="middle">
                <StyledLabel>
                  {" "}
                  Balance due as of {paymentsSlice.recurringPaymentStartDate}{" "}
                  <Tooltip
                    title={`The remaining balance due as of ${paymentsSlice.recurringPaymentStartDate} after the payment is made. This balance may change based on the interest incurred from your selected payment date.`}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                  <br />
                  (excludes convenience fees)
                </StyledLabel>
                <StyledValue>
                  {/* // TODO */}
                  {formatCurrency(currency(paymentsSlice.totalAmountDueAsOfStartDateWithInterest))}
                </StyledValue>
              </Row>
              <StyledScheduleButton type="link" onClick={() => setPlanPreviewModalVisible(true)}>
                See Full Payment Schedule
              </StyledScheduleButton>
            </StyledPaymentSummaryCard>
            <StyledDiv>
              <StyledButton icon={<ArrowLeftOutlined />} type="link" onClick={onPrevious}>
                Go Back
              </StyledButton>
              <StyledPayButton
                block
                size="large"
                type="primary"
                onClick={() => setShowDisclosureModal(true)}
                disabled={isBulkCreatePaymentIntentsLoading}
              >
                {paymentsSlice.convenienceFee?.fee > 0
                  ? `Confirm Payment (${formatCurrency(paymentsSlice.convenienceFee?.fee)})`
                  : `Setup Payment Plan`}
              </StyledPayButton>
            </StyledDiv>
            <StyledDisclosure>
              Attention:{" "}
              {paymentsSlice.convenienceFee?.message ||
                "You are authorizing the payment service provider to charge your payment method for the principal amount and a convenience fee. For ACH payments, this will appear on your statement as two separate transactions. For card payments, this will appear as one transaction. You understand that you are paying a fee for the convenience of making this payment by phone and that you can avoid this fee by mailing a check or money order to our office."}
            </StyledDisclosure>
          </Col>
        </Flex>
      </StyledForm>
      {isPlanPreviewModalVisible && (
        <Modal
          open={isPlanPreviewModalVisible}
          onCancel={() => setPlanPreviewModalVisible(false)}
          footer={null}
          width={350}
        >
          <Space direction="vertical">
            <Table
              bordered
              columns={[
                {
                  title: "Payment Date",
                  dataIndex: "scheduledDate",
                  key: "scheduledDate",
                  render: (scheduledDate) => formatDate(scheduledDate),
                },
                {
                  title: "Payment Amount",
                  dataIndex: "totalAmount",
                  key: "totalAmount",
                  render: (totalAmount) => formatCurrency(totalAmount),
                },
              ]}
              dataSource={paymentsSlice.paymentsSchedule.filter(
                (each) => currency(each.totalAmount).value > 0,
              )}
              pagination={{
                pageSize: 5,
                hideOnSinglePage: true,
              }}
            />
          </Space>
        </Modal>
      )}
      {showDisclosureModal && (
        <Modal
          open={showDisclosureModal}
          title="Acknowledgement and Agreement of Disclosures"
          onCancel={() => setShowDisclosureModal(false)}
          footer={null}
          maskClosable={false}
          width={700}
          closable
        >
          <Steps current={currentStep} size="small">
            {steps.map((item) => (
              <Steps.Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div>{steps[currentStep].content}</div>
          <div>
            {currentStep < steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {
                  if (!isTermsConditionsChecked) {
                    message.error("Check terms and conditions box to continue");
                    return;
                  }

                  setCurrentStep((prevStep) => prevStep + 1);
                }}
              >
                Next
              </Button>
            )}

            {currentStep > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                }}
                onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
              >
                Previous
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {
                  if (!isConvenienceDisclosureChecked) {
                    message.error("Check the convenience fee disclosure box to continue");
                    return;
                  }
                  form.submit();
                }}
                loading={isPosting}
              >
                Proceed with payment
              </Button>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default PaymentPlan;
