import { batch, useDispatch, useSelector } from "react-redux";
import { Button, Card, Skeleton } from "antd";
import currency from "currency.js";
import { useGetConsumerPortalAccountsQuery } from "features/consumerPortalDashboard/accountsAPI";
import PaymentScheduleCard from "features/consumerPortalPaymentPage/components/paymentScheduleCard";
import {
  PAYMENT_VIEW_SCREEN_ENUMS,
  selectPaymentsSlice,
  setCurrentView,
  setupAsOneTimePayment,
  setPaymentsSchedule,
  setupAsInitialPaymentPlan,
} from "features/consumerPortalPaymentPage/paymentsSlice";
import {
  getPaymentsScheduleForOneTimePayment,
  getPaymentsScheduleInfoWithInterest,
} from "features/payments/paymentDefaults";
import Content from "layouts/consumerPortal/content";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  useBulkCreatePaymentIntentsMutation,
  useCalculateBalanceFromScheduleMutation,
} from "features/consumerPortalPaymentPage/paymentsAPI";

const StyledDiv = styled.div`
  margin-top: 24px;
  margin-left: 16px;
  & span {
    margin-left: 12px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
  float: right;
`;

function SelectPaymentOption() {
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const { data: accounts, isLoading } = useGetConsumerPortalAccountsQuery();
  const [
    calculateBalanceFromSchedule,
    // { isLoading: isCalculateBalanceFromScheduleLoading, error },
  ] = useCalculateBalanceFromScheduleMutation();
  const [bulkCreatePaymentIntents, { isLoading: isBulkCreatePaymentIntentsLoading, error }] =
    useBulkCreatePaymentIntentsMutation();

  const debtTotal = Object.values(paymentsSlice.selectedAccounts).reduce(
    (accumulator, selectedAccount) => {
      const account = accounts?.find(
        (debtorAccount) => debtorAccount.uuid === selectedAccount.uuid,
      );
      return currency(accumulator, { precision: 4 }).add(account?.totalBalance ?? 0);
    },
    0,
  );

  const onPrevious = () => {
    dispatch(setCurrentView(PAYMENT_VIEW_SCREEN_ENUMS.SelectAccountsForPayment));
  };

  const onSelect = async (paymentMethod) => {
    const action = {
      full: PAYMENT_VIEW_SCREEN_ENUMS.SetupFullPayment,
      partial: PAYMENT_VIEW_SCREEN_ENUMS.SetupPartialPayment,
      plan: PAYMENT_VIEW_SCREEN_ENUMS.SetupPaymentPlanConfiguration,
    }[paymentMethod];

    const originalTotalAmount = currency(debtTotal).value;
    if (paymentMethod === "full") {
      // Interest can accrue if the full payment is set to future dates.
      // Since this is the first click, we don't have to worry about it on this page.
      const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
        accountIds: null, // Consumer portal won't have account Ids, only UUIDs.
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        paymentAmount: originalTotalAmount,
      });

      const paymentsScheduleInfoWithInterest = await getPaymentsScheduleInfoWithInterest({
        isInterestApplicable: paymentsSlice.isInterestApplicable,
        paymentsScheduleWithoutInterest: paymentsSchedule,
        totalAmountDueAsOfStartDateWithoutInterest: null,
        calculateBalanceFromSchedule,
      });
      batch(() => {
        dispatch(
          setupAsOneTimePayment({
            paymentIntentType: "full",
            originalTotalAmount,
            totalPaymentAmount: originalTotalAmount,
          }),
        );
        dispatch(
          setPaymentsSchedule({
            paymentsSchedule: paymentsScheduleInfoWithInterest.paymentsSchedule,
            totalAmountDueAsOfStartDateWithInterest:
              paymentsScheduleInfoWithInterest.totalAmountDueAsOfStartDateWithInterest,
          }),
        );
      });
    } else if (paymentMethod === "partial") {
      if (paymentsSlice.isInterestApplicable) {
        const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
          accountIds: null, // Consumer portal won't have account Ids, only UUIDs.
          accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
          paymentAmount: 0,
        });
        const paymentsScheduleInfoWithInterest = await getPaymentsScheduleInfoWithInterest({
          isInterestApplicable: paymentsSlice.isInterestApplicable,
          paymentsScheduleWithoutInterest: paymentsSchedule,
          totalAmountDueAsOfStartDateWithoutInterest: null,
          calculateBalanceFromSchedule,
        });
        batch(() => {
          dispatch(
            setupAsOneTimePayment({
              paymentIntentType: "partial",
              partialPaymentAmount: 0,
              originalTotalAmount,
              totalPaymentAmount: 0,
            }),
          );

          dispatch(
            setPaymentsSchedule({
              paymentsSchedule: paymentsScheduleInfoWithInterest.paymentsSchedule,
              totalAmountDueAsOfStartDateWithInterest:
                paymentsScheduleInfoWithInterest.totalAmountDueAsOfStartDateWithInterest,
            }),
          );
        });
      } else {
        const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
          accountIds: null, // Consumer portal won't have account Ids, only UUIDs.
          accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
          paymentAmount: paymentsSlice.originalTotalAmount,
        });
        batch(() => {
          dispatch(
            setupAsOneTimePayment({
              paymentIntentType: "partial",
              partialPaymentAmount: 0,
              originalTotalAmount,
              totalPaymentAmount: 0,
            }),
          );

          dispatch(
            setPaymentsSchedule({
              paymentsSchedule,
              // No-op, since this flow doesn't calculate interest.
              totalAmountDueAsOfStartDateWithInterest: originalTotalAmount,
            }),
          );
        });
      }
    } else if (paymentMethod === "plan") {
      const result = await bulkCreatePaymentIntents({
        accountUuids: paymentsSlice.selectedAccounts.map((account) => account.uuid),
        maxNumberOfPayments: 1,
        recurringPaymentStartDate: paymentsSlice.recurringPaymentStartDate,
        recurringPaymentInterval: paymentsSlice.recurringPaymentInterval,
        paymentIntentType: "full",
        // Always hardcoded to false for Consumer Portal, as consumer doesn't get to select themselves
        // whether or not they want the remainder included.
        isIncludeRemainderToLastPayment: false,
      });

      if ("data" in result) {
        const numberOfPayments = result.data?.numberOfRecurringPayments;
        const recurringPaymentAmount = result.data?.firstRecurringPaymentIntentData?.totalAmount;
        const recurringPaymentStartDate =
          result.data?.firstRecurringPaymentIntentData?.scheduledDate;
        const recurringPaymentInterval = result.data?.recurringPaymentInterval;
        const totalAmountDue = result.data?.totalAmount;
        const paymentsSchedule = result.data?.paymentIntentsData;

        // Reset back to initial payment plan states
        dispatch(
          setupAsInitialPaymentPlan({
            originalTotalAmount: totalAmountDue,
            totalPaymentAmount: recurringPaymentAmount,
            numberOfPayments,
            recurringPaymentAmount,
            recurringPaymentInterval,
            recurringPaymentStartDate,
            paymentsSchedule,
          }),
        );
      }
    }

    dispatch(setCurrentView(action));
  };

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Content>
      <h3>Select Your Payment Option</h3>
      <Card>
        <PaymentScheduleCard type="full" onSelect={onSelect} />
        <PaymentScheduleCard type="partial" onSelect={onSelect} />
        <PaymentScheduleCard type="plan" onSelect={onSelect} />
        <StyledDiv>
          <div style={{ fontStyle: "italic", textAlign: "center" }}>
            By authorizing this transaction, you understand and agree that all current and postdated
            payments will be applied to the balance of the debt. You also understand that making a
            payment may not prevent the remaining debt from being charged-off in accordance to the
            terms of the contract executed between you and the creditor. In the event of a
            charge-off, all payment arrangements may be voided. Visit{" "}
            <Link to="/contact">Contact Us</Link> if you have any questions.
          </div>
        </StyledDiv>
      </Card>
      <StyledButton icon={<ArrowLeftOutlined />} onClick={onPrevious}>
        Previous: Select Payment Accounts
      </StyledButton>
    </Content>
  );
}

export default SelectPaymentOption;
