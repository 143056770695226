import api, { transformRequest, transformResponse } from "services/api";

export const paymentsApi = api
  .enhanceEndpoints({ addTagTypes: ["ConsumerPortalPaymentIntents"] })
  .injectEndpoints({
    endpoints: (build) => ({
      bulkCreatePaymentIntents: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-intents/bulk-create/preview/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      calculateBalanceFromSchedule: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-intents/calculate-balance-from-schedule/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
      calculateConvenienceFees: build.mutation({
        query: (payload) => ({
          url: `/api/v1/consumer/payment-intents/convenience-fees/`,
          method: "POST",
          body: transformRequest(payload),
        }),
        transformResponse: (response) => transformResponse(response),
      }),
    }),
    overrideExisting: false,
  });
export const {
  useCalculateBalanceFromScheduleMutation,
  useCalculateConvenienceFeesMutation,
  useBulkCreatePaymentIntentsMutation,
} = paymentsApi;
