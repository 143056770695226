import { BankFilled, CloseOutlined, CreditCardOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Modal, message } from "antd";
import { useArchivePaymentMethodMutation } from "features/consumerPortalPaymentMethods/paymentMethodsAPI";
import { useLazyFetchPaymentIntentsQuery } from "features/consumerPortalUpcomingPayments/paymentIntentsAPI";
import styled from "styled-components";
import { PAYMENT_INTENT_STATUS } from "common/constants";

const { confirm } = Modal;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  text-align: center;
  width: 350px !important;
  height: 180px;
`;

const StyledActiveCard = styled(Card)`
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  &.ant-card {
    min-width: 400px !important;
  }
  &:hover {
    box-shadow: #f5f5f5 0px 0px 2px inset;
  }
`;

const StyledCreditCardOutlined = styled(CreditCardOutlined)`
  padding: 0px 16px;
  font-size: 24px;
  margin-right: 8px;
`;

const StyledBankFilled = styled(BankFilled)`
  padding: 0px 16px;
  font-size: 24px;
  margin-right: 8px;
`;

const StyledHeader = styled.h3`
  margin-bottom: 0;
  margin-top: 0;
  text-transform: capitalize;
`;

const StyledDiv = styled.div`
  color: gray;
  span {
    text-transform: capitalize;
  }
`;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  color: gray;
  font-size: 13px;
`;

const StyledCloseOutlined = styled(CloseOutlined)`
  color: gray;
  font-size: 13px;
`;

/*
    PaymentMethodCard component
    Displays a card with payment method information

    @param {Object} props.paymentMethod - Payment method object
    @param {string} props.paymentMethod.type - Payment method type, either "process_card" or "process_ach"
    @param {string} props.paymentMethod.cardNumber - Payment method card number
    @param {string} props.paymentMethod.bankAccountLast4Digits - Payment method bank account last 4 digits
    @param {string} props.paymentMethod.bankAccountName - Payment method bank account name
    @param {string} props.paymentMethod.cardLast4Digits - Payment method card last 4 digits
    @param {string} props.paymentMethod.uuid - Payment method UUID
    @param {boolean} props.hasDelete - Whether the card has a delete button
    @param {boolean} props.hasSelect - Whether the card has a select button
    @param {Function} props.onSelect - Function to call when the select button is clicked
    @returns {JSX.Element}
*/
export default function PaymentMethodCard({
  hasSelect = false,
  onSelect = (_) => {},
  hasDelete = false,
  paymentMethod: {
    type,
    bankAccountType,
    bankAccountLast4Digits,
    bankAccountName,
    cardLast4Digits,
    uuid,
  },
}) {
  const [archivePaymentMethod, { isLoading: isLoadingArchivedPaymentMethods }] =
    useArchivePaymentMethodMutation();

  // Use the lazy version of the query which only runs when explicitly triggered
  const [checkPaymentIntents, { isLoading: isLoadingPaymentIntents }] =
    useLazyFetchPaymentIntentsQuery();

  const handleArchivePaymentMethod = async (deleteScheduledPaymentIntents = true) => {
    const result = await archivePaymentMethod({
      paymentMethodUuid: uuid,
      deleteScheduledPaymentIntents,
    });
    if ("data" in result) {
      message.success("Payment method archived successfully");
    }
  };

  const handleDelete = async () => {
    try {
      // Explicitly fetch payment intents for this payment method
      const { data: activePaymentIntents } = await checkPaymentIntents({
        payment_method_uuids: [uuid],
        status: PAYMENT_INTENT_STATUS.NEW,
      });

      if (activePaymentIntents?.length > 0) {
        // Show warning modal if there are active payment intents
        confirm({
          title: "Warning: Scheduled Payments Found",
          content:
            "You are deleting a payment method that is being used in future payments. Deleting this method will also cancel those future payments which will need to be recreated with a valid payment method. Are you sure you want to proceed?",
          okText: "Proceed to delete",
          okType: "danger",
          cancelText: "Cancel",
          onOk() {
            handleArchivePaymentMethod(true);
          },
        });
      } else {
        // No scheduled payments, show simple confirmation
        confirm({
          title: "Are you sure you want to delete this payment method?",
          content: "This action cannot be undone.",
          okText: "Proceed to delete",
          okType: "danger",
          cancelText: "Cancel",
          onOk() {
            handleArchivePaymentMethod(false);
          },
        });
      }
    } catch (error) {
      message.error("Failed to check for scheduled payments. Please try again.");
    }
  };

  const WrapperComponent = hasSelect ? StyledActiveCard : StyledCard;
  const isLoading = isLoadingArchivedPaymentMethods || isLoadingPaymentIntents;

  return (
    <Flex vertical={false} align="center" onClick={hasSelect ? () => onSelect(uuid) : null}>
      <WrapperComponent
        actions={[
          hasDelete && (
            <Button
              type="text"
              icon={isLoading ? <StyledLoadingOutlined /> : <StyledCloseOutlined />}
              key="delete"
              onClick={handleDelete}
              disabled={isLoading}
            />
          ),
        ]}
      >
        {type === "process_ach" ? <StyledBankFilled /> : <StyledCreditCardOutlined />}
        {type === "process_ach" ? (
          <div>
            <StyledHeader>{bankAccountName ?? "Bank Account"}</StyledHeader>
            <StyledDiv>
              <span>{bankAccountType}</span> account ending in •••• {bankAccountLast4Digits}
            </StyledDiv>
          </div>
        ) : (
          <div>
            <StyledHeader>Credit Card</StyledHeader>
            <StyledDiv>Card ending in •••• {cardLast4Digits}</StyledDiv>
          </div>
        )}
      </WrapperComponent>

      {/* <Dropdown
          // @ts-ignore
          placement="leftTop"
          menu={{
            items: [
              {
                key: "1",
                label: "Make Primary",
              },
              {
                key: "2",
                label: "Edit",
              },
              {
                key: "3",
                label: "Delete",
              },
            ],
            onClick: onMenuClick,
          }}
          trigger={["click"]}
        >
          <Button type="text">
            <EllipsisOutlined
              style={{
                fontSize: 24,
              }}
            />
          </Button>
        </Dropdown> */}
    </Flex>
  );
}
