import api, { transformRequest, transformResponse } from "services/api";

export const paymentIntentsApi = api
  .enhanceEndpoints({
    addTagTypes: ["ConsumerPortalPaymentHistory", "ConsumerPortalPaymentIntents"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchPaymentIntents: build.query({
        query: (params = {}) => ({
          url: `/api/v1/consumer/payment-intents/`,
          method: "POST",
          body: transformRequest(params),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        providesTags: [{ type: "ConsumerPortalPaymentIntents", id: "LIST" }],
      }),
      createPaymentIntent: build.mutation({
        query: (body) => ({
          url: `/api/v1/consumer/payment-intents/create/`,
          method: "POST",
          body: transformRequest(body),
          headers: { "aktos-suppress-error": "true" },
        }),
        transformResponse: (response) => transformResponse(response),
        invalidatesTags: [{ type: "ConsumerPortalPaymentIntents", id: "LIST" }],
      }),
      deletePaymentIntent: build.mutation({
        query: (body) => ({
          url: `/api/v1/consumer/payment-intents/bulk-delete/`,
          method: "POST",
          body: transformRequest(body),
          headers: { "aktos-suppress-error": "true" },
        }),
        invalidatesTags: (result, error, arg) => [
          { type: "ConsumerPortalPaymentIntents", id: arg.uuid },
          { type: "ConsumerPortalPaymentIntents", id: "LIST" },
        ],
      }),
    }),

    overrideExisting: false,
  });

export const {
  useCreatePaymentIntentMutation,
  useFetchPaymentIntentsQuery,
  useLazyFetchPaymentIntentsQuery,
  useDeletePaymentIntentMutation,
} = paymentIntentsApi;
